<template>
  <div class="home">
    <Loading :show="loading" />
    <empty :show="empty" />
    <RoomList :items="rooms" v-show="!loading" />
  </div>
</template>

<script>
// @ is an alias to /src
import RoomList from '@/components/RoomList'
import Loading from '@/components/Loading'
import Empty from '@/components/Empty'
import { getRoomList } from '@/api/room'

export default {
  name: 'Home',
  components: {
    RoomList, Loading, Empty
  },
  data: () => ({
    rooms: [],
    loading: false
  }),
  computed: {
    empty () {
      return !this.loading && this.rooms.length === 0
    }
  },
  mounted () {
    this.$store.commit('toggleNavigation', true)
    this.loadRoomList()
  },
  methods: {
    loadRoomList () {
      this.loading = true
      getRoomList().then(data => {
        this.rooms = data
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }).catch(e => {
        console.error(e)
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.home {
  height: 100%;
}
</style>
