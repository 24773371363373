<template>
  <v-container fill-height fluid v-show="show">
    <v-row align="center" justify="center">
      <div :id="containerId"></div>
    </v-row>
  </v-container>
</template>

<script>
import lottie from 'lottie-web'
export default {
  name: 'Loading',
  props: {
    show: { type: Boolean, defaultValue: false }
  },
  data: () => ({
    anim: null,
    containerId: ''
  }),
  watch: {
    show (val) {
      if (val) {
        this.anim.play()
      } else {
        this.anim.pause()
      }
    }
  },
  created () {
    this.containerId = this.randomId()
  },
  mounted () {
    console.log(this.containerId)
    this.initAnim()
  },
  beforeDestroy () {
    console.log('anim will destroy')
    this.anim.destroy()
  },
  methods: {
    initAnim () {
      this.anim = lottie.loadAnimation({
        container: document.getElementById(this.containerId),
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/lottie/loading-40-paperplane.json'
      })
      if (this.show) {
        this.anim.play()
      }
    },
    randomId () {
      return `anim_${Date.now()}`
    }
  }
}
</script>

<style scoped>
  #primary_loading_container {
    width: 80%;
    max-width: 300px;
  }
</style>
