<template>
  <v-container>
    <v-row dense>
      <v-col
        v-for="(item, i) in items"
        :key="i"
        cols="12"
      >
        <v-card
          :color="'#1F7087'"
          dark
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title
                class="headline"
                v-text="item.name"
              ></v-card-title>

              <v-card-subtitle v-text="item.createUserName"></v-card-subtitle>

              <v-card-actions>
                <v-btn
                  class="ml-2 mt-5"
                  outlined
                  rounded
                  small
                  @click="enterRoom(item)"
                >
                  进入房间
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar
              class="ma-3"
              width="150"
              height="125"
              tile
              rounded
            >
              <v-img :src="item.coverPicture"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'RoomList',
  data: () => ({
    item: {
      id: 1,
      name: '',
      coverPicture: '',
      createUserId: '',
      createUserName: '',
      createTime: 0
    }
  }),
  props: {
    items: Array
  },
  methods: {
    enterRoom (item) {
      // 设置当前房间信息
      this.$store.commit('moduleRoom/setCurrentRoom', item)
      // 进入直播间
      this.$router.push(`/live/${item.id}`)
    }
  }
}
</script>

<style scoped>

</style>
